import { Route, Routes } from 'react-router-dom';
import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider }  from "@apollo/client";



import SiteHeader from './components/SiteHeader';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import Page from './pages/Page';
import Article from './pages/Article';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound';
import User from './pages/User';
import Login from './pages/Login';
import Logout from './pages/Logout';
import { ToastContainer } from 'react-toastify';
import { backendUrl } from './helpers';

//apollo client
const client = new ApolloClient({
  uri: backendUrl+'/graphql',
  cache:  new InMemoryCache()
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <div className="app">
      <SiteHeader />
      <Routes>
          <Route exact path ="/" element={<Homepage/>}/>
          <Route path="/users/editprofile" element={<User/>}/>
          <Route path="/users/login" element={<Login/>}/>
          <Route path="/users/logout" element={<Logout/>}/>
          <Route path ="/:slug" element={<Page/>}/>
          <Route path ="/ihmiset/:slug" element={<Profile/>}/>
          <Route path="/uutiset/:slug" element={<Article/>}/>
          <Route path ="*" element={<NotFound/>}/>
          
      </Routes>
      <ToastContainer style={{ width: "50vw" }}/>

      <Footer/>

    </div>

    </ApolloProvider>
    
    
  );
  
}
  

      
export default App;