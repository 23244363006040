import React, { useState } from 'react'
import { Link } from 'react-router-dom'



export default function Dropdown(props) {

    const [dropdown, setDropdown] = useState(false);



  return (
    <ul className={dropdown ? "navbar-dropdown clicked" : "navbar-dropdown"} onClick={() => setDropdown(!dropdown)}>

        { props.pages && props.pages.data.map(item => {
            return (
                <li key={item.id}>
                    <Link className='navbar-dropdown-item' to={item.attributes.slug} onClick={() => setDropdown(!dropdown)}>{item.attributes.navbarAlias ? item.attributes.navbarAlias : item.attributes.title}</Link>
                </li>
            )
        })}

        { props.links && props.links.data.map(item => {
            return (
                <li key={item.id}>
                    <a href={item.attributes.link} onClick={() => setDropdown(!dropdown)}>{item.attributes.title}</a>
                </li>
            )
        })}
        
    </ul>
  )
}
