import React, { useState } from 'react'
import Dropdown from './Dropdown';
import { Link } from 'react-router-dom';

export default function MenuItem(props) {

    const [dropdown, setDropdown] = useState(false);

    var dropdownNotEmpty = false;
    if (props.links !== undefined) dropdownNotEmpty = true;
    if (props.pages !== undefined) dropdownNotEmpty = true;

        return (
            <>
                <span className='categorySpan' onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
                    <Link to={props.to} className={props.className}>{props.title}</Link>
                    { dropdown && dropdownNotEmpty && <Dropdown links={props.links} pages={props.pages}/>}
                </span>
            </>
        )
    
}
