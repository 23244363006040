import React from "react"
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';


const ARTICLES = gql`
    query GetArticles {
        articles (sort: "date:desc") {
            data {
                id,
                attributes { 
                    title,
                    date,
                    slug
                }
            }
        }
    }
`


const Articles = () => {

    const { loading, error, data } = useQuery(ARTICLES);

    if (loading) return <p>Ladataan uutisia...</p>

    try {
        return (
            <div className="articles-list">

                {data.articles.data.map(article => (
                    <div key={article.id}>
                        <Link to={"/uutiset/"+article.attributes.slug} className="article-link"><span className="articles-list-date">{new Date(Date.parse(article.attributes.date)).toLocaleString('fi-FI', {year: 'numeric', month: 'numeric', day: 'numeric'})}</span> <span className="articles-list-title">{article.attributes.title}</span></Link>
                    </div>
                ))}
              
            </div>
          )
    } catch {
        return <p>Virhe ladattaessa uutisia.</p>
    }
  
};

export default Articles;
