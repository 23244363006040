import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import ReactMarkdown from "react-markdown";
import sanapilvi from "../assets/pp-sanapilvi.jpg";
import { backendUrl } from '../helpers';


import NotFound from './NotFound';

const PROFILE = gql`query GetProfile($slug: String!) {
    usersPermissionsUsers(filters: { slug: { eq: $slug } }) {
        data {
            id,
            attributes {
                fullName,
                description,
                introduction,
                municipality,
                public_email,
                phone,
                twitter,
                facebook,
                instagram,
                linkedin,
                uusisuomi,
                website,
                youtube,
                telegram,
                tiktok,
                avatarURL,
                mastodon,
                substack,
                ongoingCandidacy {
                        election,
                        district,
                        number
                }
            }
        }
    }
}
`

export default function Profile() {
    const { slug } = useParams();
    const { loading, error, data } = useQuery(PROFILE, {
        variables: { slug : slug }
    });

    

    function imageExists(image_url){
        var http = new XMLHttpRequest();
        http.open('HEAD', image_url, false);
        http.send();
        return http.status === 200;
    }

    if (loading) return <div className='page-body'><p>Ladataan sisältöä...</p></div>

    if (data.usersPermissionsUsers.data.toString() === "") {
        return <NotFound/>
    };

    console.log(data);
    try {
        return (
            <div className="page-content">
                {data.usersPermissionsUsers.data.map(user => (

                    <>
                        <h1 className="page-title">
                            {user.attributes.fullName}
                        </h1>
                        
                        <div className="page-body">

                        {user.attributes.ongoingCandidacy && <div className='candidacy'>
                        <h1>Ehdokkaana: {user.attributes.ongoingCandidacy.election}</h1>
                        {user.attributes.ongoingCandidacy.district && <h4>Vaalipiiri: {user.attributes.ongoingCandidacy.district}</h4>}
                        {user.attributes.ongoingCandidacy.number && <h3>Äänestä minua numerolla {user.attributes.ongoingCandidacy.number}!</h3>}
                        </div>}

                            <div className="profile-basics">
                                
                                <div className="profile-info">
                                    <p><b>Paikkakunta: </b>{user.attributes.municipality}</p>
                                    <p className="smoltext">{user.attributes.description}</p>
                                    <p>
                                    {user.attributes.website && !user.attributes.website.startsWith("javascript:") && <a href={user.attributes.website}>Kotisivu<br/></a>}
                                    {user.attributes.substack && !user.attributes.substack.startsWith("javascript:") && <a href={user.attributes.substack}>Substack<br/></a>}
                                    {user.attributes.mastodon && !user.attributes.mastodon.startsWith("javascript:") && <a href={user.attributes.mastodon}>Mastodon<br/></a>}
                                    {user.attributes.twitter && !user.attributes.twitter.startsWith("javascript:") && <a href={user.attributes.twitter}>Twitter<br/></a>}
                                    {user.attributes.facebook && !user.attributes.facebook.startsWith("javascript:") && <a href={user.attributes.facebook}>Facebook<br/></a>}
                                    {user.attributes.instagram && !user.attributes.instagram.startsWith("javascript:") && <a href={user.attributes.instagram}>Instagram<br/></a>}
                                    {user.attributes.linkedin && !user.attributes.linkedin.startsWith("javascript:") && <a href={user.attributes.linkedin}>LinkedIn<br/></a>}
                                    {user.attributes.tiktok && !user.attributes.tiktok.startsWith("javascript:") && <a href={user.attributes.tiktok}>TikTok<br/></a>}
                                    {user.attributes.youtube && !user.attributes.youtube.startsWith("javascript:") && <a href={user.attributes.youtube}>YouTube<br/></a>}
                                    {user.attributes.uusisuomi && !user.attributes.uusisuomi.startsWith("javascript:") && <a href={user.attributes.uusisuomi}>Uusi Suomi<br/></a>}
                                    {user.attributes.telegram && !user.attributes.telegram.startsWith("javascript:") && <a href={user.attributes.telegram}>Telegram<br/></a>}
                                    {user.attributes.public_email && <a href={"mailto:"+user.attributes.public_email}>{user.attributes.public_email}<br/></a>}
                                    {user.attributes.phone && <>Puhelin: {user.attributes.phone}</>}
                                    </p>
                                </div>
                                    
                                <div className="profile-avatar">
                                    {user.attributes.avatarURL && user.attributes.avatarURL.startsWith("/") && imageExists(backendUrl+user.attributes.avatarURL) && <img className="profile-image" src={backendUrl+user.attributes.avatarURL} alt={"Kuva henkilöstä "+user.attributes.fullName}></img>}
                                    {user.attributes.avatarURL && user.attributes.avatarURL.startsWith("/") && !imageExists(backendUrl+user.attributes.avatarURL) && <img className="profile-image" src={sanapilvi} alt={"Oletusprofiilikuva"}></img>}
                                    {user.attributes.avatarURL && !user.attributes.avatarURL.startsWith("/") && imageExists(user.attributes.avatarURL) && <img className="profile-image" src={user.attributes.avatarURL} alt={"Kuva henkilöstä "+user.attributes.fullName}></img>}
                                    {user.attributes.avatarURL && !user.attributes.avatarURL.startsWith("/") && !imageExists(user.attributes.avatarURL) && <img className="profile-image" src={sanapilvi} alt={"Oletusprofiilikuva"}></img>}
                                    {!user.attributes.avatarURL && <img className="profile-image" src={sanapilvi} alt={"Oletusprofiilikuva"}></img>}
                                </div>
                            </div>
                            <h2>Kuvaus</h2>
                            <ReactMarkdown>{user.attributes.introduction}</ReactMarkdown>
                        </div>
                    </>
                )
                    
                )}
                

                
                    

            </div>
        )
    } catch {
        return <p>Virhe ladattaessa sisältöä.</p>
    }
}
