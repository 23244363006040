import React from "react"

const Calendar = () => {
  return (
        //tähän se kalenterin embed <iframe></iframe> tagi seuraavan rivin tilalle, jos tätä tarvitsee joskus muuttaa
      <iframe src="https://calendar.google.com/calendar/embed?showPrint=0&amp;showCalendars=0&amp;mode=AGENDA&amp;height=800&amp;wkst=2&amp;hl=fi&amp;bgcolor=%23FFFFFF&amp;src=piraattikalenteri%40gmail.com&amp;color=%235229A3&amp;ctz=Europe%2FHelsinki" style={{ borderWidth: 0 }} width="1140" height="800" frameBorder="0"></iframe>
  )
};

export default Calendar;
