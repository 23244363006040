import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import MenuItem from './MenuItem';

import logo from '../assets/piraattipuolue-logo-nega-569.png';

const CATEGORIES = gql`
    query GetNavbarCategories {
        navbarcategories {
            data {
                id,
                attributes {
                    title,
                    link,
                    pages {
                        data {
                            id,
                            attributes {
                                title,
                                slug,
                                navbarAlias
                            }
                        }
                    }
                    navbar_links {
                        data {
                            id,
                            attributes {
                                title,
                                link
                            }
                        }
                    }
                }
            }
        }
    }
`

export default function SiteHeader() {
    const { loading, error, data } = useQuery(CATEGORIES);

    if (loading) return <p>Ladataan...</p>



    try{
        return (
            <div className="site-header">
            <Link to="/" className="logo"><img src={logo} alt='Piraattipuolueen logo' className='header-logo'></img></Link>
            <nav className="categories">
                {data.navbarcategories.data.map(category => {

                    return (
                    <MenuItem 
                        key={category.id}
                        to={category.attributes.link}
                        title={category.attributes.title}
                        className="category"
                        pages={category.attributes.pages}
                        links={category.attributes.navbar_links}>

                    </MenuItem>
                )})}
            </nav>
            </div>
        )
    } catch {
        return <p>Virhe ladattaessa kategorioita: {error}</p>
    }
        
}
