import Cookies from 'js-cookie';


export const storeUser = (data) => {
    var exp = new Date(new Date().getTime() + 3 * 60 * 60 * 1000);
    Cookies.set('user', JSON.stringify({
        username: data.user.username,
        jwt: data.jwt
    }), {
        expires: exp
    })
}

export const userData = () => {
    const stringifiedUser = Cookies.get('user') || {};
    try {
        return JSON.parse(stringifiedUser || {});
    } catch {
        return JSON.parse('""');
    }
}

//tähän URLin perusosoite ILMAN kauttaviivaa. sovelluksen eri osissa kauttaviiva lisätään siellä minne se tarvitaan.
//export const backendUrl = 'http://localhost:1337' //dev
export const backendUrl = 'https://strapi.awaclus.eu' //prod

export const frontendUrl = 'http://localhost:3000'