import React from 'react';
import { useQuery, gql } from '@apollo/client';
import sanapilvi from "../assets/pp-sanapilvi.jpg";
import { backendUrl } from '../helpers';
import { Link } from 'react-router-dom';

const POSITION = gql`
query GetPosition($id: ID!) {
    position(id: $id) {
        data {
            id,
            attributes { 
                title,
                users_permissions_users {
                    data {
                        id,
                        attributes {
                            fullName,
                            slug,
                            description,
                            municipality,
                            public_email,
                            phone,
                            twitter,
                            facebook,
                            instagram,
                            linkedin,
                            uusisuomi,
                            website,
                            youtube,
                            telegram,
                            tiktok,
                            avatarURL
                        }
                    }
                }
            }
        }
    }
}
`



export default function Gallery(props) {

    function imageExists(image_url){
        var http = new XMLHttpRequest();
        http.open('HEAD', image_url, false);
        http.send();
        return http.status === 200;
    }

    function getID() {
        return props.id;
    }

    const { loading, error, data } = useQuery (POSITION, {
        variables: { id: getID() }
    });

    

    if (loading) return <p>Ladataan henkilögalleriaa...</p>

    try {
        return (
            <div>
                <h3 className='gallery-title'>{ data.position.data.attributes.title }</h3>



                <div className="gallery-data">

                
                {data.position.data.attributes.users_permissions_users.data.map(user => (


                        <div key={user.id} className="person-brief">
                            <Link to={"/ihmiset/"+user.attributes.slug}><div>
                                
                                {user.attributes.avatarURL && user.attributes.avatarURL.startsWith("/") && imageExists(backendUrl+user.attributes.avatarURL) && <img className="gallery-image" src={backendUrl+user.attributes.avatarURL} alt={"Kuva henkilöstä "+user.attributes.fullName}></img>}
                                {user.attributes.avatarURL && user.attributes.avatarURL.startsWith("/") && !imageExists(backendUrl+user.attributes.avatarURL) && <img className="gallery-image" src={sanapilvi} alt={"Oletusprofiilikuva"}></img>}
                                {user.attributes.avatarURL && !user.attributes.avatarURL.startsWith("/") && imageExists(user.attributes.avatarURL) && <img className="gallery-image" src={user.attributes.avatarURL} alt={"Kuva henkilöstä "+user.attributes.fullName}></img>}
                                {user.attributes.avatarURL && !user.attributes.avatarURL.startsWith("/") && !imageExists(user.attributes.avatarURL) && <img className="gallery-image" src={sanapilvi} alt={"Oletusprofiilikuva"}></img>}
                                {!user.attributes.avatarURL && <img className="gallery-image" src={sanapilvi} alt={"Oletusprofiilikuva"}></img>}
                                <h4 className="gallery-name">{user.attributes.fullName}</h4>
                                <p className="smoltext">{user.attributes.description}</p>
                                <p className="gallery-municipality">{user.attributes.municipality}</p>
                            </div></Link>
                        </div>
        
                    ))}
                
                </div>

            </div>
          )
    } catch {
        return <p>Virhe ladattaessa henkilögalleriaa.</p>
    }

}
