import React, { Component } from 'react'

import logo from '../assets/piraattipuolue-logo-nega-569.png';

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <img src={logo} alt='Piraattipuolueen logo' className='footer-logo'></img>
        <h2 className='footer-slogan'>Valitse vapaus!</h2>
        <p className="footer-links"><a href="/yhteystiedot">Yhteystiedot</a> | <a href="https://wiki.piraattipuolue.fi">Wiki</a> | <a href="https://arkisto.piraattipuolue.fi">Arkisto</a></p>
      </div>
    )
  }
}
