import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Input } from 'reactstrap';
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { storeUser } from '../helpers';
import { userData } from '../helpers';
import { backendUrl } from '../helpers';

const Login = () => {
    const initialUser = {password: "", identifier: ""};
    const [user, setUser] = useState(initialUser);
    const { jwt } = userData();

    const navigate = useNavigate();

    useEffect(() => {
        if (jwt) {
            navigate('/users/editprofile');
        }
        
    }, [jwt, navigate])

    const handleChange = ({target}) => {
        setUser((currentUser) => ({
            ...currentUser,
            [target.name]: target.value,
        }))
    };

    const handleKeydown = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const handleLogin = async() => {

        const url = backendUrl+'/api/auth/local';
        try {
            if (user.identifier && user.password) {
                const res = await axios.post(url, user);

                if (res.data.jwt) {
                    storeUser(res.data);
                    setUser(initialUser);
                    navigate("/users/editprofile")
                }
            }
        } catch (error) {

            toast.error("Virhe kirjauduttaessa sisään. Tarkista, että kirjautumistiedot ovat oikein.", {
                hideProgressBar: true,
                position: 'top-center',
                newestOnTop: false,
                theme: "dark",
                autoClose: 7500,
                draggable: true,
                pauseOnHover: true,
                closeOnClick: true,

            })

            console.log(error);
        }
    };


    return (
        <div className="login">
            <div>
                <p><b>Huom!</b> Tietoturvasyistä on suositeltavaa käyttää incognito-tilaa tai yksityistä tilaa. Voit avata incognito-ikkunan tai yksityisen ikkunan painamalla <b>Ctrl+Shift+N</b> useimmissa selaimissa.</p>
                <p>Firefoxissa pikanäppäin on <b>Ctrl+Shift+P</b>. Macilla pikanäppäimissä käytetään Ctrlin sijasta ⌘-näppäintä.</p>
                <p>Jos kuitenkin käytät tavallista ikkunaa, <b>muista kirjautua ulos</b> tehtyäsi halutut muutokset.</p>
                <h2>Kirjaudu:</h2>
                <FormGroup>
                    <Input type="email" name="identifier" placeholder="Käyttäjätunnus (yleensä etunimi.sukunimi)" onChange={handleChange} on onKeyDown={handleKeydown} value={user.identifier}/>
                </FormGroup>
                <FormGroup>
                    <Input type="password" name="password" placeholder="Salasana" onChange={handleChange} onKeyDown={handleKeydown} value={user.password}/>
                </FormGroup>
                <Button color="primary" onClick={handleLogin}>
                    Kirjaudu
                </Button>
                <p></p>
                <p><i>Tämä sivu käyttää evästeitä sisäänkirjautumiseen. Eväste vanhenee 3 tunnin kuluttua sisäänkirjautumisesta.</i></p>
            </div>
        </div>
    )
}

export default Login;