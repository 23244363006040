import axios from "axios";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { backendUrl } from "../helpers";


const DeleteAvatar = ({ userId, token, username, avatarURL, setIsUserUpdated }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };


  const updateUserAvatarId = async (avatarId, avatarURL) => {
    try {
      await axios.put(
        backendUrl+`/api/user/me`,
        { avatarId, avatarURL },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      );
      setIsUserUpdated(true);
    } catch (error) {
      console.log({ error });
    }
  };


  const handleDelete = async () => {
    try {
      updateUserAvatarId(null, null);
      setModal(false);
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <div className="deleteAvatar">
      <Button size="sm" color="danger" onClick={toggle}>
        {`Poista kuva`}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{`Vahvista`}</ModalHeader>
        <ModalBody>
            <p>
                Haluatko varmasti poistaa profiilikuvan? <b>Tätä ei voi peruuttaa</b>. Profiilissasi näkyy oletusprofiilikuva, kunnes lisäät uuden kuvan.
            </p>
        </ModalBody>
        <ModalFooter>
        
          
          
          <Button color="danger" onClick={handleDelete}>
            Poista
          </Button>
          <Button color="secondary" onClick={toggle}>
            Peruuta
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteAvatar;