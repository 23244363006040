import React from 'react'

export default function NotFound() {
  return (
    <div className="page-content">
      <div className='page-body'>
        <h1>404</h1>
        <p>Tässä osoitteessa ei ole mitään. Varmista, että olet kirjoittanut osoitteen oikein.</p>
      </div>
    </div>
  )
}
