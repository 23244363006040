import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Logout = (props) => {

    const navigate = useNavigate();

    useEffect(() => {

        Cookies.remove('user')
        navigate('/users/login');
    }, [navigate])

    return (null);
};

export default Logout;