import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Form, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { backendUrl } from "../helpers";


const UploadAvatar = ({ userId, token, username, avatarURL, setIsUserUpdated }) => {
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState(null);

  const toggle = () => {
    setModal(!modal);
  };

  const handleFileChange = ({ target: { files } }) => {
    if (files?.length) {
      const { type, size } = files[0];
      var success = true;

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = function (e) {
        var image = new Image();

        image.src = e.target.result;

        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (width >= 300 && height >= 300) {
            if (width > height*1.1) {
              toast.info("Kuva ei ole neliö. Huomioi, että kuvan vasemmasta ja oikeasta reunasta rajautuu alueita kuvan ulkopuolelle.");
            }
            if (width < height*0.9) {
              toast.info("Kuva ei ole neliö. Huomioi, että kuvan ylä- ja alareunasta rajautuu alueita kuvan ulkopuolelle.");
            }
            if (success) {
              document.getElementById('avatarPreview').src=image.src;
            }
            
          } else {
            success = false;
            toast.error("Kuvan on oltava vähintään 300x300 px");
            setFile(null);
            document.getElementById('avatarFile').value=null;
            document.getElementById('avatarPreview').src="";
          }
        }
      }

      if (size < 10 || size > 1048576) {
        success = false;
        toast.error("Kuvan maksimikoko on 1 MiB");
      }

      if (type === "image/png" || type === "image/jpeg") {

      } else {
        toast.error("Kuvan on oltava png- tai jpg-muodossa");
        success = false;
      }

      if (success) {
        setFile(files[0]);
      } else {
        document.getElementById('avatarFile').value=null;
        document.getElementById('avatarPreview').src="";
      }

    }
  };

  const updateUserAvatarId = async (avatarId, avatarURL) => {
    try {
      await axios.put(
        backendUrl+`/api/user/me`,
        { avatarId, avatarURL },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        }
      );
      setIsUserUpdated(true);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      toast.error("Tiedosto vaaditaan", {
        hideProgressBar: true,
      });
      return;
    }

    try {
      const files = new FormData();
      files.append("files", file);
      files.append("name", `${username} avatar`);

      const {
        data: [{ id, url }],
      } = await axios.post(backendUrl+`/api/upload`, files, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${token}`,
        },
      });
      updateUserAvatarId(id, url);
      setFile(null);
      setModal(false);
    } catch (error) {
      console.log({ error });
    }
  };

  

  return (
    <div>
      <Button size="sm" color="primary" onClick={toggle}>
        {`Vaihda kuva`}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{`Valitse profiilikuva`}</ModalHeader>
        <ModalBody>
            <p>
                Käytä profiilikuvana valokuvaa, jossa näkyy kasvosi edestä päin kuvattuna. Kuvan minimikoko on 300*300px ja tiedoston maksimikoko 1 MiB. Tiedostotyypiksi käy png tai jpg.
            </p>

            <p>Esikatselu:</p>
            <img id="avatarPreview" className="gallery-image" alt="Esikatselu"></img>
          <Form>
            <FormGroup>
              <Label for="avatarFile">Tiedosto</Label>
              <Input
                type="file"
                name="file"
                id="avatarFile"
                onChange={handleFileChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        
          <Button color="secondary" onClick={toggle}>
            Peruuta
          </Button>
          
          <Button color="primary" onClick={handleSubmit}>
            Lähetä
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UploadAvatar;