import React from "react";
import vaalipiirit from "../assets/vaalipiirit.png";

const DistrictOrgs = () => {


  return (
    <div className="district-orgs">
        <div className="district-map">
            <p>
                

                <img src={vaalipiirit} alt="" useMap="#Map"/>
            </p>
            <map id="Map" name="Map">
                <area title="Helsinki" alt="Helsinki" coords="158,575,192,581,193,599,158,599" shape="poly" href="/helsingin-piraatit/"/>
                <area title="Uusimaa" alt="Uusimaa" coords="155,584,156,572,200,579,204,557,152,555,131,554,122,579,105,583,103,600,145,596" shape="poly" href="/uudenmaan-piraatit/"/>
                <area title="Varsinais-Suomi" alt="Varsinais-Suomi" coords="129,554,119,577,103,582,97,597,45,555,59,525,81,535,105,529,114,535,106,545" shape="poly" href="/lounais-suomen-piraatit/"/>
                <area title="Satakunta" alt="Satakunta" coords="102,527,104,457,68,477,60,522,81,533" shape="poly" href="/lounais-suomen-piraatit/"/>
                <area title="Häme" alt="Häme" coords="194,551,201,500,181,496,178,507,162,508,162,518,120,533,114,543,128,554,169,543" shape="poly" href="/hameen-piraatit/"/>
                <area title="Pirkanmaa" alt="Pirkanmaa" coords="107,458,108,527,117,530,158,514,154,478,134,449" shape="poly" href="/pirkanmaan-piraatit/"/>
                <area title="Kaakkois-Suomi" alt="Kaakkois-Suomi" coords="212,560,238,565,306,488,294,490,270,441,259,450,266,466,218,450,210,464,203,469,204,504,207,521,198,535,201,549" shape="poly" href="/kaakkois-suomen-piraatit/"/>
                <area title="Savo-Karjala" alt="Savo-Karjala" coords="310,482,344,418,287,366,260,368,253,379,219,361,204,371,201,414,212,449,252,460,259,439,268,435,281,441,296,486" shape="poly" href="/savo-karjalan-piraatit/"/>
                <area title="Vaasa" alt="Vaasa" coords="65,468,64,421,132,347,166,391,161,408,146,413,153,444" shape="poly" href="/vaasan-piraatit/"/>
                <area title="Keski-Suomi" alt="Keski-Suomi" coords="174,381,196,394,210,459,199,470,201,495,183,492,176,505,162,505,157,471,141,452,157,448,150,416,165,411" shape="poly" href="/keski-suomen-piraatit/"/>
                <area title="Oulu" alt="Oulu" coords="136,344,167,386,174,376,195,391,209,358,252,373,259,363,302,368,321,217,261,216,258,252,174,267" shape="poly" href="/oulun-seudun-piraatit/"/>
                <area title="Lappi" alt="Lappi" coords="172,265,252,249,254,214,278,214,282,1,74,5,140,267" shape="poly" href="/lapin-piraatit/"/>
            </map>
        </div>
        <div>
            <h3>Piiriyhdistykset</h3>
            <ul>
                <li><a href="/helsingin-piraatit">01 Helsingin piraatit</a></li>
                <li><a href="/uudenmaan-piraatit">02 Uudenmaan piraatit</a></li>
                <li><a href="/lounais-suomen-piraatit">03 ja 04 Lounais-Suomen piraatit</a></li>
                <li><a href="/hameen-piraatit">06 Hämeen piraatit</a></li>
                <li><a href="/pirkanmaan-piraatit">07 Pirkanmaan piraatit</a></li>
                <li><a href="/kaakkois-suomen-piraatit">08 Kaakkois-Suomen piraatit</a></li>
                <li><a href="/savo-karjalan-piraatit">09 Savo-Karjalan piraatit</a></li>
                <li><a href="/vaasan-piraatit">10 Vaasan piraatit</a></li>
                <li><a href="/keski-suomen-piraatit">11 Keski-Suomen piraatit</a></li>
                <li><a href="/oulun-seudun-piraatit">12 Oulun seudun piraatit</a></li>
                <li><a href="/lapin-piraatit">13 Lapin piraatit</a></li>
            </ul>
        </div>
    </div>
  )
};

export default DistrictOrgs;
