import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import ReactMarkdown from "react-markdown";

import Gallery from '../components/Gallery';
import DistrictOrgs from '../components/DistrictOrgs';
import NotFound from './NotFound';
import Calendar from '../components/Calendar';
import Articles from '../components/Articles';

const PAGE = gql`
query GetPage($slug: String!) {
    pages(filters: { slug: { eq: $slug } }) {
        data {
            id,
            attributes { 
                title
                  body {
                  ... on ComponentComponentsButtonlink {
                    text,
                    link,
                    id,
                    __typename
                  }
                  ... on ComponentComponentsTwoButtonlinks {
                    text1,
                    text2,
                    link1,
                    link2,
                    id,
                    __typename
                  }
                  ... on ComponentComponentsThreeButtonlinks {
                    text1,
                    text2,
                    text3,
                    link1,
                    link2,
                    link3,
                    id,
                    __typename
                  }
                  ... on ComponentComponentsRichtext {
                    text,
                      id,
                    __typename
                  }
                  ... on ComponentComponentsRichtextCentered {
                    text,
                      id,
                    __typename
                  }
                  ... on ComponentComponentsGallery {
                    position {
                        data {
                            id
                        }
                    },
                    __typename
                  }
                }
            }
        }
    }
}
`

export default function Page() {
    const { slug } = useParams();
    const { loading, error, data } = useQuery(PAGE, {
        variables: { slug : slug }
    });

    if (loading) return <div className='page-body'><p>Ladataan sisältöä...</p></div>

    if (data.pages.data.toString() === "") {
        return <NotFound/>
    };

    function flatten(text, child) {
        return typeof child === 'string'
          ? text + child
          : React.Children.toArray(child.props.children).reduce(flatten, text)
      }
      
      function HeadingRenderer(props) {
        var children = React.Children.toArray(props.children)
        var text = children.reduce(flatten, '')
        var slug = text.toLowerCase().replace(/\W/g, '-')
        return React.createElement('h' + props.level, {id: 'h' + props.level + '-' + slug}, props.children)
      }
      

    console.log(error);
    try {
        return (
            <div className="page-content">
                {data.pages.data.map(page => (
                    <>
                    {
                        <><h1 key="1" className='page-title'>{page.attributes.title}</h1>
                    <div className='page-body'>
                    {page.attributes.body.map(component => (
                        <>
                        {component.__typename === "ComponentComponentsRichtextCentered" && <ReactMarkdown key={component.id} className='richtext-centered' components={{h1: HeadingRenderer, h2: HeadingRenderer, h3: HeadingRenderer, h4: HeadingRenderer, h5: HeadingRenderer, h6: HeadingRenderer}}>{component.text}</ReactMarkdown>}
                        {component.__typename === "ComponentComponentsRichtext" && <ReactMarkdown key={component.id} components={{h1: HeadingRenderer, h2: HeadingRenderer, h3: HeadingRenderer, h4: HeadingRenderer, h5: HeadingRenderer, h6: HeadingRenderer}}>{component.text}</ReactMarkdown>}
                        {component.__typename === "ComponentComponentsButtonlink" && <a href={component.link} key={component.id} className='no-decoration'><button className='buttonlink'>{component.text}</button></a>}
                        {component.__typename === "ComponentComponentsTwoButtonlinks" &&
                            <table key={component.id}>
                                <tbody>
                                    <tr>
                                        <th><a href={component.link1} className='no-decoration'><button className='buttonlink'>{component.text1}</button></a></th>
                                        <th><a href={component.link2} className='no-decoration'><button className='buttonlink'>{component.text2}</button></a></th>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        {component.__typename === "ComponentComponentsThreeButtonlinks" &&
                            <table key={component.id}>
                                <tbody>
                                    <tr>
                                        <th><a href={component.link1} className='no-decoration'><button className='buttonlink'>{component.text1}</button></a></th>
                                        <th><a href={component.link2} className='no-decoration'><button className='buttonlink'>{component.text2}</button></a></th>
                                        <th><a href={component.link3} className='no-decoration'><button className='buttonlink'>{component.text3}</button></a></th>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        {component.__typename === "ComponentComponentsGallery" && <Gallery id={component.position.data.id}></Gallery>}

                        {component.__typename === "ComponentComponentsDistrictOrgs" && <DistrictOrgs/>}

                        {component.__typename === "ComponentComponentsCalendar" && <Calendar/>}

                        {component.__typename === "ComponentComponentsArticles" && <Articles/>}
                        </>
                        
                    )
                        
                    )}
                    </div></>
                    }
                    
                    </>
                ))}
                
                
                

                    

            </div>
        )
    } catch {
        return <p>Virhe ladattaessa sisältöä.</p>
    }
}
