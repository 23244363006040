import React from 'react'

export default function Homepage() {
  return (

    
    <div className='page-content'>
      <div className='page-body'>
        <h1>Etusivu</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget nisi ac felis suscipit eleifend. Aenean vehicula purus sed libero bibendum, in tincidunt quam rhoncus. Proin luctus nibh vel ex volutpat, sit amet fermentum orci aliquet. Quisque gravida, risus eget bibendum aliquam, dolor nisl scelerisque velit, ut facilisis libero lectus sed nulla. Integer sed metus a turpis malesuada varius. Fusce eget lectus non sapien varius condimentum eu vel purus. Nam suscipit lacinia elit, a placerat est laoreet quis. Sed eget libero nec nunc tincidunt hendrerit. Maecenas ac suscipit justo, nec tempus lectus.</p>
        <p>Vivamus nec euismod eros, in vulputate massa. Aliquam erat volutpat. Sed vel aliquet urna. Vivamus mattis justo vel arcu iaculis, at ullamcorper turpis ultricies. Fusce non ex sed elit lacinia dictum. Quisque elementum, erat a fermentum vulputate, dolor justo tempor neque, eget efficitur quam est at justo. Nullam nec libero eu quam venenatis congue id non ex. Integer suscipit dui in urna ultricies, ac euismod metus viverra. Sed nec quam vitae tortor pellentesque tristique. In hac habitasse platea dictumst. Sed a odio eget ante aliquet consectetur. Donec euismod, urna eget congue efficitur, ligula urna aliquam quam, eget varius libero turpis in ante. Fusce auctor elit ac ipsum faucibus, at ve</p>
      </div>
    </div>
  )
}
