import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import ReactMarkdown from "react-markdown";
import NotFound from './NotFound';
import { Link } from 'react-router-dom';

const ARTICLE = gql`
query GetArticle($slug: String!) {
    articles(filters: { slug: { eq: $slug } }) {
        data {
            id,
            attributes { 
                title,
                body,
                date
                
            }
        }
    }
}
`

export default function Article() {
    const { slug } = useParams();
    const { loading, error, data } = useQuery(ARTICLE, {
        variables: { slug : slug }
    });

    if (loading) return <div className='page-body'><p>Ladataan sisältöä...</p></div>

    if (data.articles.data.toString() === "") {
        return <NotFound/>
    };

    console.log(error);
    try {
        return (
            <div className="page-content">
                {data.articles.data.map(article => (
                    
                    <>
                    
                    {
                        
                        <><h1 key="1" className='page-title'><Link to="/uutiset" style={{color: 'white', textDecoration: 'none'}}>Uutiset</Link></h1>
                        <div className='page-body'>
                            <h1 className="article-title">{article.attributes.title}</h1>
                            <h6 className="article-date">{new Date(Date.parse(article.attributes.date)).toLocaleString('fi-FI', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</h6>
                            <ReactMarkdown>{article.attributes.body}</ReactMarkdown>
                        </div></>
                    }
                    
                    </>
                ))}
                
                
                

                    

            </div>
        )
    } catch {
        return <p>Virhe ladattaessa sisältöä.</p>
    }


}