import React, { useEffect, useState } from 'react';
import { userData, backendUrl } from '../helpers';
import axios from 'axios';
import UploadAvatar from '../components/UploadAvatar';
import sanapilvi from "../assets/pp-sanapilvi.jpg";
import DeleteAvatar from '../components/DeleteAvatar';
import { Form, Input, FormGroup, Label, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from "react-toastify";
import MDEditor from "@uiw/react-md-editor";
import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";
import ReactMarkdown from "react-markdown";
import { useNavigate } from 'react-router-dom';

const User = () => {

    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const [isUserUpdated, setIsUserUpdated] = useState(false);
    var { jwt } = userData();
    const [introductionValue, setIntroductionValue] = useState("");
    const [modal, setModal] = useState(false);

    const handleName = async () => {
        const fullName = document.getElementById("fullName").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { fullName },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Nimi päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleEmail = async () => {
        const public_email = document.getElementById("public_email").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { public_email },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Sähköposti päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handlePhone = async () => {
        const phone = document.getElementById("phone").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { phone },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Puhelinnumero päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleTwitter = async () => {
        const twitter = document.getElementById("twitter").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { twitter },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Twitter-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleFacebook = async () => {
        const facebook = document.getElementById("facebook").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { facebook },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Facebook-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleInstagram = async () => {
        const instagram = document.getElementById("instagram").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { instagram },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Instagram-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleLinkedin = async () => {
        const linkedin = document.getElementById("linkedin").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { linkedin },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("LinkedIn-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleUusisuomi = async () => {
        const uusisuomi = document.getElementById("uusisuomi").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { uusisuomi },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Uusi Suomi-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleYoutube = async () => {
        const youtube = document.getElementById("youtube").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { youtube },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("YouTube-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleTelegram = async () => {
        const telegram = document.getElementById("telegram").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { telegram },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Telegram-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleTiktok = async () => {
        const tiktok = document.getElementById("tiktok").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { tiktok },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("TikTok-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleMastodon = async () => {
        const mastodon = document.getElementById("mastodon").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { mastodon },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Mastodon-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleWebsite = async () => {
        const website = document.getElementById("website").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { website },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Kotisivu päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleSubstack = async () => {
        const substack = document.getElementById("substack").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { substack },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Substack-linkki päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleMunicipality = async () => {
        const municipality = document.getElementById("municipality").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { municipality },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Kunta päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }







      const handleDescription = async () => {
        const description = document.getElementById("description").value;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { description },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Lyhyt kuvaus päivitetty!")
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const handleIntroduction = async () => {
        const introduction = introductionValue;
        try {
            var { jwt } = userData();
            await axios.put(
                backendUrl+`/api/user/me`,
                { introduction },
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${jwt}`,
                },
                }
            );
            setIsUserUpdated(true);
            toast.success("Pidempi kuvaus päivitetty!")
            setModal(false);
            } catch (error) {
                alert("Istunto vanhentunut. Kirjaudu sisään.");
                navigate("/users/logout");
            }
      }

      const toggle = () => {
        setModal(!modal);
      };

      const handleEdit = () => {
        setIntroductionValue(user.introduction);
        setModal(true);
      }
    
    useEffect(() => {
        const getProfileData = async () => {
            try {
                const { data } = await axios.get(backendUrl+`/api/users/me`, {
                    headers: {
                        Authorization: `bearer ${jwt}`,
                    },
                });
                setUser(data);
                setIsUserUpdated(false);
                
                
            } catch (error) {
                navigate('/users/logout');
            }
        };
        getProfileData();
        
    }, [jwt, isUserUpdated, navigate]);


    if (jwt) {
        
        return (
            <div className='edit-profile'>
                <h2>Tervetuloa, {user.fullName} ({user.username})!</h2>
                <a href='/users/logout' className="btn btn-primary">Kirjaudu ulos</a>

                <p><br/>Täällä voit muokata profiiliasi. Kaikki tiedot päivittyvät <a href={`/ihmiset/${user.slug}`} target="_blank" rel="noreferrer">julkiseen profiiliisi</a> heti, kun klikkaat "Päivitä".</p>
                <div className='profile'>
                    <div className='avatar'>
                        <div className='avatar-wrapper'>
                            {user.avatarURL ? (
                                <img src={backendUrl+`${user.avatarURL}`} alt={`${user.username} avatar`} className='profile-image'></img>
                            ) : (
                                <img src={sanapilvi} alt='oletusprofiilikuva' className='profile-image'></img>
                            )}
                            <div className='avatar-buttons'>
                                <UploadAvatar token={jwt} userId={user.id} username={user.username} avatarUrl={user.avatarURL} setIsUserUpdated={setIsUserUpdated}></UploadAvatar>
                                <DeleteAvatar token={jwt} userId={user.id} username={user.username} avatarUrl={user.avatarURL} setIsUserUpdated={setIsUserUpdated}></DeleteAvatar>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4>Yhteystiedot</h4>
                        <p>Pakolliset kentät on merkitty tähdellä.</p>
                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Etu- ja sukunimi (*):</Label>
                                <Input className="form-text" type="text" name="fullName" id="fullName" placeholder="Syötä etu- ja sukunimesi" defaultValue={user.fullName}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleName}>
                                Päivitä
                            </Button>
                        </Form>


                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Julkinen e-mail:</Label>
                                <Input className="form-text" type="text" name="public_email" id="public_email" defaultValue={user.public_email}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleEmail}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Julkinen puhelin:</Label>
                                <Input className="form-text" type="text" name="phone" id="phone" defaultValue={user.phone}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handlePhone}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Kotisivu:</Label>
                                <Input className="form-text" type="text" name="website" id="website" defaultValue={user.website}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleWebsite}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Kunta:</Label>
                                <Input className="form-text" type="text" name="municipality" id="municipality" defaultValue={user.municipality}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleMunicipality}>
                                Päivitä
                            </Button>
                        </Form>

                        <div className='margin'></div>
                        <h4>Sosiaalinen media</h4>
                        <p>Syötä kenttiin kokonaiset URL-osoitteet, esim. https://www.twitter.com/EsimerkkiKäyttäjä</p>
                        
                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Twitter:</Label>
                                <Input className="form-text" type="text" name="twitter" id="twitter" defaultValue={user.twitter}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleTwitter}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Facebook:</Label>
                                <Input className="form-text" type="text" name="facebook" id="facebook" defaultValue={user.facebook}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleFacebook}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Instagram:</Label>
                                <Input className="form-text" type="text" name="instagram" id="instagram" defaultValue={user.instagram}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleInstagram}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">LinkedIn:</Label>
                                <Input className="form-text" type="text" name="linkedin" id="linkedin" defaultValue={user.linkedin}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleLinkedin}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Uusi Suomi:</Label>
                                <Input className="form-text" type="text" name="uusisuomi" id="uusisuomi" defaultValue={user.uusisuomi}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleUusisuomi}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">YouTube:</Label>
                                <Input className="form-text" type="text" name="youtube" id="youtube" defaultValue={user.youtube}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleYoutube}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Telegram:</Label>
                                <Input className="form-text" type="text" name="telegram" id="telegram" defaultValue={user.telegram}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleTelegram}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">TikTok:</Label>
                                <Input className="form-text" type="text" name="tiktok" id="tiktok" defaultValue={user.tiktok}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleTiktok}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Mastodon:</Label>
                                <Input className="form-text" type="text" name="mastodon" id="mastodon" defaultValue={user.mastodon}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleMastodon}>
                                Päivitä
                            </Button>
                        </Form>

                        <Form className="form-line">
                            <FormGroup className="form-line">
                                <Label className="form-label">Substack:</Label>
                                <Input className="form-text" type="text" name="substack" id="substack" defaultValue={user.substack}/>
                            </FormGroup>
                            <Button className="form-button" color="primary" onClick={handleSubstack}>
                                Päivitä
                            </Button>
                        </Form>
                                
                                <div className='margin'></div>
                                <h4>Kuvaus</h4>
                                

                        <Form>
                            <FormGroup>
                                <Label>Lyhyt kuvaus (asema puolueessa ja max. 2 titteliä):</Label>
                                <Input type="text" name="description" id="description" defaultValue={user.description} maxLength={180}/>
                            </FormGroup>
                            <Button color="primary" onClick={handleDescription}>
                                Päivitä
                            </Button>
                        </Form>
                        <div className='margin'>

                        </div>
                        <Form>
                            <FormGroup>
                                <Label>Pidempi kuvaus:</Label>
                                <div id="introduction-edit">
                                    <Button color="primary" onClick={handleEdit}>Muokkaa</Button>
                                </div>
                                <span id="introduction-mdpreview">
                                    <ReactMarkdown>{user.introduction}</ReactMarkdown>
                                </span>
                                <Modal isOpen={modal} toggle={toggle} size="lg">
                                    <ModalHeader toggle={toggle}>{`Muokkaa pidempää kuvausta`}</ModalHeader>
                                    <ModalBody>
                                        <ul>
                                            <li>Voit käyttää kuvauksessa markdown-muotoilua.</li>
                                            <li>Klikkaamalla editorin painikkeita saat lisättyä haluamasi muotoilun maalatulle tekstille.</li>
                                            <li>Vasenta puolta muokataan, oikealla puolella on esikatselu.</li>
                                            <li>Editorin oikeasta yläkulmasta saat sen koko näytölle.</li>
                                            <li>?-painikkeesta löydät ohjeet markdown-muotoiluun.</li>
                                            <li>Voit kertoa itsestäsi vapaamuotoisesti, esim. 3 arvoa, jotka kuvastavat sinua piraattina. Pyri kuitenkin ytimekkääseen tekstiin.</li>
                                        </ul>
                                        <span id="introduction-mdeditor">
                                            <MDEditor height={400} value={introductionValue} onChange={setIntroductionValue} className='mdeditor'/>
                                        </span>

                                    </ModalBody>
                                    <ModalFooter>
                                    
                                    <Button color="secondary" onClick={toggle}>
                                        Peruuta
                                    </Button>
                                    
                                    <Button color="primary" onClick={handleIntroduction}>
                                        Päivitä
                                    </Button>
                                    </ModalFooter>
                                </Modal>                                
                            </FormGroup>
                            
                        </Form>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='edit-profile'>
                <h2>Virhe</h2>
                <p>Toiminto edellyttää kirjautumista sisään. <a href='/users/login'>Kirjaudu sisään</a></p>
            </div>
        )
    }
    
}

export default User;